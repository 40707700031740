import React from 'react'
import {
    NAVIGATE_MEMBER_LOGIN,
    NAVIGATE_MEMBER_DASHBOARD,
    NAVIGATE_MYFAMILY,
    NAVIGATE_MEMBER_PROFILE,
    NAVIGATE_REFERRAL,
    NAVIGATE_FEEDBACK,
    NAVIGATE_MEMBER_REGISTER,
    NAVIGATE_BUY,
    NAVIGATE_MEMBER_MYACTIVITY,
    NAVIGATE_MEMBER_CLAIMSUMMARY,
    NAVIGATE_MEMBER_CLAIMSUBMIT,
    NAVIGATE_FAMILY_MEMBER_ADD,
    NAVIGATE_MILEAGE_CALCULATOR,
    NAVIGATE_MEMBER_MYFLIGHT,
    NAVIGATE_TRANSFER,
    NAVIGATE_FAMILY_POOLING,
    NAVIGATE_MEMBER_OVERVIEW,
    NAVIGATE_MIGRATE_POINTS,
    NAVIGATE_FORGOT_PASSWORD,
    NAVIGATE_RESET_PASSWORD,
    NAVIGATE_ACTIVATE,
    NAVIGATE_CLUB,
    NAVIGATE_CLUB_PAYCONFIRM,
    NAVIGATE_CLUB_ADDBOOSTER,
    NAVIGATE_OFFERS,
    NAVIGATE_PARTNERS,
    NAVIGATE_PROFILE_COMPLETION,
    NAVIGATE_MEMBER_EXTEND_EXPIRY,
    NAVIGATE_CORPORATE_MYACTIVITY,
    NAVIGATE_CORPORATE_OVERVIEW,
    NAVIGATE_CORPORATE_REGISTER,
    NAVIGATE_CORPORATE_MANAGE_PEOPLE,
    NAVIGATE_CORPORATE_PROFILE,
    NAVIGATE_MEMBER_TRAVEL_COMPANION
} from '../utils/urlConstants';
import {
    HOME, POINT_TRANSACTION,
    DASHBOARD, PROGRAM_TYPE_INDIVIDUAL,
    CLAIM_LIST, SUBMIT_CLAIM, BUY,
    TRANSFER, MY_ACTIVITY, MY_FLIGHT,
    MIGRATE_POINTS, MY_FAMILY,
    ADD_FAMILY, MILEAGE_OVERVIEW,
    FAMILY_POOLING, PROFILE,
    FEEDBACK, REFERRAL, TRANSACTIONS,
    CLUB_MEMBERSHIP_HOME,
    CLUB_MEMBERSHIP_SUBSCRIBE,
    CLUB_MEMBERSHIP_ADD_BOOSTER, OFFERS,
    PARTNERS, CALCULATOR, CLAIM_POINTS,
    LOGIN, ENROL, FORGOT_PASSWORD,
    RESET_PASSWORD, USER_ACTIVATION,
    PROFILE_COMPLETION, EXTEND_MILES_EXPIRY,
    CORPORATE_OVERVIEW, CORPORATE_MANAGE_PEOPLE,
    CORPORATE, CORPORATE_PROFILE, CORPORATE_REGISTER, CORPORATE_ACTIVITY,
    COMPANION_LIST,
    MEMBER
} from '../utils/Constants';
import loadable from '@loadable/component';
import { BROWSER_STORAGE_TYPE_LOCAL, BROWSER_STORAGE_TYPE_SESSION, BROWSER_STORAGE_KEY_PROGRAM_TYPE } from '../utils/storage.utils';
import Loader from '../components/fieldbank/loader/Loader';
import { _URL_PARAMS_ACCESS_TOKEN, _URL_PARAMS_LANG } from '../utils/urlParams.utils';

export const ROUTER_CONFIG = {
    "default": {
        "url": NAVIGATE_MEMBER_LOGIN,
        "page": {
            "title": "default.pageTitle",
            "description": "default.pageDescription"
        }
    },
    "config": {
        "showSwitchOption": false,
        "isCookieEnabled" : true
    },
    "welcomeMessageConfig": {
        "enable": false,
        "timer": 5000
    },
    "routes": [{
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_LOGIN,
        "isProtected": false,
        "clearToken": true,
        "enabled": true,
        "header": false,
        "breadcrumbs": [],
        "mainClassName": "guest-view page__login",
        "component": loadable(() => import('../../impl/login'), { fallback: <Loader /> }),
        "browserStorage": [
            {
                "type": BROWSER_STORAGE_TYPE_LOCAL,
                "setItems": [{ "key": BROWSER_STORAGE_KEY_PROGRAM_TYPE, "value": PROGRAM_TYPE_INDIVIDUAL }]
            },
            {
                "type": BROWSER_STORAGE_TYPE_SESSION,
                "setItems": []
            }
        ],
        "key": {
            "menuReference": LOGIN,
            "pageReference": NAVIGATE_MEMBER_LOGIN
        },
        "hasClassFormRow": false,
        "defaultProps": {
            "title": "login.seoTitle",
            "description": "login.seoDescription"
        }
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_REGISTER,
        "isProtected": false,
        "clearToken": true,
        "enabled": true,
        "header": false,
        "breadcrumbs": [],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/enrolment'), { fallback: <Loader /> }),
        "browserStorage": [],
        "defaultProps": {
            "page": "form.enrolment"
        },
        "key": {
            "menuReference": ENROL,
            "pageReference": NAVIGATE_MEMBER_REGISTER
        },
        "imageBannerConfig": {
            "show": true,
            "titleId": "header.bannerTitle"
        },
        "hasClassFormRow": false,
        "subClassName": "pt-0 pb-5"
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_DASHBOARD,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [],
        "mainClassName": "container page__dashboard",
        "component": loadable(() => import('../../ui/dashboard'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": DASHBOARD,
            "pageReference": NAVIGATE_MEMBER_DASHBOARD
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MILEAGE_CALCULATOR,
        "isProtected": false,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": POINT_TRANSACTION,
                "link": ""
            },
            {
                "id": "",
                "name": CALCULATOR,
                "link": ""
            }
        ],
        "mainClassName": "container page__calculator",
        "component": loadable(() => import('../../ui/mileagecalculator'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": TRANSACTIONS,
            "pageReference": NAVIGATE_MILEAGE_CALCULATOR
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_CLAIMSUMMARY,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CLAIM_POINTS,
                "link": ""
            },
            {
                "id": "",
                "name": CLAIM_LIST,
                "link": ""
            }
        ],
        "mainClassName": "container page__claim-miles",
        "component": loadable(() => import('../../ui/claimsummary'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CLAIM_POINTS,
            "pageReference": NAVIGATE_MEMBER_CLAIMSUMMARY
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_CLAIMSUBMIT,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CLAIM_POINTS,
                "link": ""
            },
            {
                "id": "",
                "name": SUBMIT_CLAIM,
                "link": ""
            }
        ],
        "mainClassName": "container page__claim-miles",
        "component": loadable(() => import('../../impl/claimsubmit'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CLAIM_POINTS,
            "pageReference": NAVIGATE_MEMBER_CLAIMSUBMIT
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_BUY,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": POINT_TRANSACTION,
                "link": ""
            },
            {
                "id": "",
                "name": BUY,
                "link": ""
            }
        ],
        "mainClassName": "container page__buy-miles",
        "component": loadable(() => import('../../ui/buypoints'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": TRANSACTIONS,
            "pageReference": NAVIGATE_BUY
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_TRANSFER,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": POINT_TRANSACTION,
                "link": ""
            },
            {
                "id": "",
                "name": TRANSFER,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/transfermiles'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": TRANSACTIONS,
            "pageReference": NAVIGATE_TRANSFER
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_TRAVEL_COMPANION,
        "isProtected": true,
        "hasSidePanel": false,
        "enabled": false,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MEMBER,
                "link": ""
            },
            {
                "id": "",
                "name": COMPANION_LIST,
                "link": ""
            }
        ],
        "mainClassName": "container  page__claim-miles",
        "component": loadable(() => import('../../ui/travelcompanion'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": PROFILE,
            "pageReference": NAVIGATE_MEMBER_TRAVEL_COMPANION
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_MYACTIVITY,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": POINT_TRANSACTION,
                "link": ""
            },
            {
                "id": "",
                "name": MY_ACTIVITY,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/myactivity'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": TRANSACTIONS,
            "pageReference": NAVIGATE_MEMBER_MYACTIVITY
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_MYFLIGHT,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MY_FLIGHT,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/myflight'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MY_FLIGHT,
            "pageReference": NAVIGATE_MEMBER_MYFLIGHT
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MIGRATE_POINTS,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": false,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MIGRATE_POINTS,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassConvertMiles",
        "component": loadable(() => import('../../ui/migratepoints'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MIGRATE_POINTS,
            "pageReference": NAVIGATE_MIGRATE_POINTS
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MYFAMILY,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": false,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MY_FAMILY,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassFamily",
        "component": loadable(() => import('../../ui/familymembers'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": FAMILY_POOLING,
            "pageReference": NAVIGATE_MYFAMILY
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_FAMILY_MEMBER_ADD,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MY_FAMILY,
                "link": ""
            },
            {
                "id": "",
                "name": ADD_FAMILY,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../impl/addfamilymember'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MY_FAMILY,
            "pageReference": NAVIGATE_FAMILY_MEMBER_ADD
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_OVERVIEW,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MILEAGE_OVERVIEW,
                "link": ""
            }
        ],
        "mainClassName": "container page__overview",
        "component": loadable(() => import('../../ui/overview'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MILEAGE_OVERVIEW,
            "pageReference": NAVIGATE_MEMBER_OVERVIEW
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_FAMILY_POOLING,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MY_FAMILY,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../impl/familypooling/v2'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MY_FAMILY,
            "pageReference": NAVIGATE_FAMILY_POOLING
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_PROFILE,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MEMBER,
                "link": ""
            },
            {
                "id": "",
                "name": PROFILE,
                "link": ""
            }
        ],
        "mainClassName": "container page__profile",
        "component": loadable(() => import('../../ui/profile'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MEMBER,
            "pageReference": NAVIGATE_MEMBER_PROFILE
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_FEEDBACK,
        "isProtected": false,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": FEEDBACK,
                "link": ""
            }
        ],
        "mainClassName": "container page__cs-request",
        "component": loadable(() => import('../../ui/feedback_v2'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": FEEDBACK,
            "pageReference": NAVIGATE_FEEDBACK
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_REFERRAL,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": MEMBER,
                "link": ""
            },
            {
                "id": "",
                "name": REFERRAL,
                "link": ""
            }
        ],
        "mainClassName": "container page__referal",
        "component": loadable(() => import('../../ui/referral'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": MEMBER,
            "pageReference": NAVIGATE_REFERRAL
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "corp_prg",
        "url": NAVIGATE_CORPORATE_OVERVIEW,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CORPORATE,
                "link": NAVIGATE_CORPORATE_OVERVIEW
            },
            {
                "id": "",
                "name": CORPORATE_OVERVIEW,
                "link": ""
            }
        ],
        "mainClassName": "container page__overview",
        "component": loadable(() => import('../../ui/overview'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CORPORATE,
            "pageReference": NAVIGATE_CORPORATE_OVERVIEW
        },
        "apiTriggerConfig": {
            "profile": true,
            "nominee": true,
            "accountSummary": true
        },
        "hasClassFormRow": true,
    }, {
        "programIdentifier": "corp_prg",
        "url": NAVIGATE_CORPORATE_MYACTIVITY,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CORPORATE,
                "link": NAVIGATE_CORPORATE_OVERVIEW
            },
            {
                "id": "",
                "name": CORPORATE_ACTIVITY,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/myactivity'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CORPORATE,
            "pageReference": NAVIGATE_CORPORATE_MYACTIVITY
        },
        "apiTriggerConfig": {
            "profile": true,
            "nominee": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "corp_prg",
        "url": NAVIGATE_CORPORATE_MANAGE_PEOPLE,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CORPORATE,
                "link": NAVIGATE_CORPORATE_OVERVIEW
            },
            {
                "id": "",
                "name": CORPORATE_MANAGE_PEOPLE,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/manageaccountusers'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CORPORATE,
            "pageReference": NAVIGATE_CORPORATE_MANAGE_PEOPLE
        },
        "apiTriggerConfig": {
            "profile": true,
            "nominee": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "corp_prg",
        "url": NAVIGATE_CORPORATE_PROFILE,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CORPORATE,
                "link": NAVIGATE_CORPORATE_OVERVIEW
            },
            {
                "id": "",
                "name": CORPORATE_PROFILE,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/profile'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CORPORATE,
            "pageReference": NAVIGATE_CORPORATE_PROFILE
        },
        "apiTriggerConfig": {
            "profile": true,
            "nominee": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "corp_prg",
        "url": NAVIGATE_CORPORATE_REGISTER,
        "isProtected": true,
        "clearToken": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CORPORATE_REGISTER,
                "link": ""
            }
        ],
        "accessConfig": {
            "conditions": [{
                "patterns": [{
                    "pattern": "props.profileData.memberAccount.memberDynamicAttributes[?(@.attributeCode=='CM')].attributeValue",
                    "key": "{VAL1}",
                    "defaultVal": "'-'"
                }, {
					"pattern": "props.profileData.memberAccount.memberDynamicAttributes[?(@.attributeGroupName=='Corporate Details'&&@.attributeValue=='NA')].attributeValue",
					"key": "{VAL2}",
					"defaultVal": "-"
				}],
                "enabled": true,
                "expression": "{VAL1}!='-'&&'{VAL2}'=='-'",
                "messageId": "enrolment.corporate_already_created"
            }, {
                "patterns": [{
					"pattern": "props.profileData.memberAccount.memberDynamicAttributes[?(@.attributeGroupName=='Corporate Details'&&@.attributeValue=='NA')].attributeValue",
					"key": "{VAL1}",
					"defaultVal": "-"
				}],
                "enabled": true,
                "expression": "'{VAL1}'=='NA'",
                "messageId": "enrolment.corporate_traveler"
            }]
        },
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/enrolment'), { fallback: <Loader /> }),
        "browserStorage": [],
        "defaultProps": {
            "page": "form.enrolment.corporate"
        },
        "key": {
            "menuReference": CORPORATE_REGISTER,
            "pageReference": NAVIGATE_CORPORATE_REGISTER
        },
        "apiTriggerConfig": {
            "profile": false,
            "nominee": false,
            "accountSummary": false
        },
        "hasClassFormRow": false,
        "subClassName": "pt-0 pb-5"
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_FORGOT_PASSWORD,
        "isProtected": false,
        "clearToken": true,
        "header": false,
        "enabled": true,
        "breadcrumbs": [],
        "mainClassName": "guest-view",
        "component": loadable(() => import('../../impl/forgotpsw'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": FORGOT_PASSWORD,
            "pageReference": NAVIGATE_FORGOT_PASSWORD
        },
        "defaultProps": {
            "title": "forgotpsw.seoTitle",
            "description": "forgotpsw.seoDescription"
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_RESET_PASSWORD,
        "isProtected": false,
        "clearToken": true,
        "enabled": true,
        "header": false,
        "breadcrumbs": [],
        "mainClassName": "guest-view",
        "component": loadable(() => import('../../ui/resetpsw'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": RESET_PASSWORD,
            "pageReference": NAVIGATE_RESET_PASSWORD
        },
        "defaultProps": {
            "title": "resetPsw.seoTitle",
            "description": "resetPsw.seoDescription"
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_CLUB,
        "isProtected": true,
        "clearToken": false,
        "enabled": false,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CLUB_MEMBERSHIP_HOME,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/club/index'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CLUB_MEMBERSHIP_HOME,
            "pageReference": NAVIGATE_CLUB
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_CLUB_PAYCONFIRM,
        "isProtected": true,
        "clearToken": false,
        "enabled": false,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CLUB_MEMBERSHIP_HOME,
                "link": NAVIGATE_CLUB
            },
            {
                "id": "",
                "name": CLUB_MEMBERSHIP_SUBSCRIBE,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/club/Sections/PayConfirm'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CLUB_MEMBERSHIP_HOME,
            "pageReference": NAVIGATE_CLUB_PAYCONFIRM
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_CLUB_ADDBOOSTER,
        "isProtected": true,
        "clearToken": false,
        "enabled": false,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": CLUB_MEMBERSHIP_HOME,
                "link": NAVIGATE_CLUB
            },
            {
                "id": "",
                "name": CLUB_MEMBERSHIP_ADD_BOOSTER,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/club/Sections/AddBoosterPack'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": CLUB_MEMBERSHIP_HOME,
            "pageReference": NAVIGATE_CLUB_ADDBOOSTER
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_OFFERS,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": OFFERS,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/promotions/OffersList'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": OFFERS,
            "pageReference": NAVIGATE_OFFERS
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_PARTNERS,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": PARTNERS,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/promotions/PartnersList'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": PARTNERS,
            "pageReference": NAVIGATE_PARTNERS
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_MEMBER_EXTEND_EXPIRY,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": EXTEND_MILES_EXPIRY,
                "link": ""
            }
        ],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/extendexpiry'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": TRANSACTIONS,
            "pageReference": NAVIGATE_MEMBER_EXTEND_EXPIRY
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": false
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_PARTNERS + '/:id',
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": PARTNERS,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/promotions/PromotionDetails'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": PARTNERS,
            "pageReference": NAVIGATE_PARTNERS + '/:id'
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_OFFERS + '/:id',
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "breadcrumbs": [
            {
                "id": "",
                "name": HOME,
                "link": NAVIGATE_MEMBER_DASHBOARD
            },
            {
                "id": "",
                "name": OFFERS,
                "link": ""
            }
        ],
        "mainClassName": "container pageClassProfile",
        "component": loadable(() => import('../../ui/promotions/PromotionDetails'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": OFFERS,
            "pageReference": NAVIGATE_OFFERS + '/:id'
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_PROFILE_COMPLETION,
        "isProtected": true,
        "clearToken": false,
        "hasSidePanel": false,
        "enabled": true,
        "header": false,
        "breadcrumbs": [],
        "mainClassName": "container",
        "component": loadable(() => import('../../ui/profilecompletion'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": PROFILE_COMPLETION,
            "pageReference": NAVIGATE_PROFILE_COMPLETION
        },
        "apiTriggerConfig": {
            "profile": true,
            "accountSummary": true
        },
        "hasClassFormRow": true
    }, {
        "programIdentifier": "defa_prg",
        "url": NAVIGATE_ACTIVATE,
        "isProtected": false,
        "clearToken": true,
        "hasSidePanel": false,
        "header": false,
        "enabled": true,
        "breadcrumbs": [],
        "mainClassName": "",
        "component": loadable(() => import('../../ui/useractivation'), { fallback: <Loader /> }),
        "browserStorage": [],
        "key": {
            "menuReference": USER_ACTIVATION,
            "pageReference": NAVIGATE_ACTIVATE
        },
        "hasClassFormRow": false
    }],
    [PROGRAM_TYPE_INDIVIDUAL]: {
        "dashboardUrl": NAVIGATE_MEMBER_DASHBOARD,
        "loginUrl": NAVIGATE_MEMBER_LOGIN
    },
    "footer": {
        "component": loadable(() => import('../../impl/footer'))
    },
    "header": {
        "component": loadable(() => import('../../impl/header/header'))
    },
    "urlParamConfig": {
        [_URL_PARAMS_ACCESS_TOKEN]: {
            "pattern": ["."]
        },
        [_URL_PARAMS_LANG]: {
            "pattern": [NAVIGATE_FEEDBACK]
        }
    }
}
