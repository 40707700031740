
import {
    doPostEnrol, doPostLogin,doPost
} from '../../common/utils/api'
import {
    _URL_ENROLMENT,
    _URL_LOGIN_DETAILS,
    _URL_CHECK_MEMBERSHIP_NUMBER,
    _URL_PREAFFILIATED_ENROLMENT
} from '../../common/config/config'
import {
    getApiErrorMessage
} from '../../common/utils'
import { NAVIGATE_MEMBER_DASHBOARD } from '../../common/utils/urlConstants';
import { 
    setItemToBrowserStorage, 
    clearBrowserStorage,
    BROWSER_STORAGE_KEY_TOKEN, 
    BROWSER_STORAGE_KEY_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_ENROL,
    BROWSER_STORAGE_KEY_FIRST_TIME_USER,
    BROWSER_STORAGE_KEY_COMPANY_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE,
    getItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_REFRESH_TOKEN
} from '../../common/utils/storage.utils';
import { TRUE } from './Constants';
import { 
    startButtonSpinner, 
    stopButtonSpinner
} from '../../common/components/fieldbank/loader/action';
import { ROUTER_CONFIG } from '../../common/config/routing';

// Action Type
export const ENROLMENT_DATA = "ENROLMENT_DATA";
export const ENROLMENT_ERROR = "ENROLMENT_ERROR";
export const PREAFFLIATED_DATA = "PREAFFLIATED_DATA";
export const PREAFFLIATED_ERROR = "PREAFFLIATED_ERROR";
export const PREAFFILIATED_ENROLMENT_DATA = "PREAFFILIATED_ENROLMENT_DATA";

/**
 * Creates Action of type <i>_URL_FETCH_PROFILE_DETAILS</i> with given data
 *
 * @function enrolment
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_FETCH_PROFILE_DETAILS</i>
 *
 */
export const enrolment = (payload, id,url,headers) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "enrolment"))
    await doPostEnrol(url, payload,headers)
    .then((response) => {
        dispatch(stopButtonSpinner(id, "enrolment"))
        dispatch({
            type: ENROLMENT_DATA,
            payload: response.data
        })
    })
    .catch((error) => {
        dispatch(stopButtonSpinner(id, "enrolment"))
        dispatch({
            type: ENROLMENT_ERROR,
            payload: {error: getApiErrorMessage(error.response.data)}
        })
    })
  };
}

/**
 * Creates Action of type <i>_URL_LOGIN_DETAILS</i> with given data
 *
 * @function loginDetails
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_LOGIN_DETAILS</i>
 *
 */
export const enrollLoginDetails = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "enrollLoginDetails"))
        await doPostLogin(_URL_LOGIN_DETAILS, payload)
            .then((res) => {
                dispatch(stopButtonSpinner(id, "enrollLoginDetails"))
                if (res.data.details) {
                    const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
                    const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
                    const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                    clearBrowserStorage()
                    let response = res.headers;
                    let token = response["ps-token"];
                    let refreshToken = response["ps-refreshToken"];
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE, programType)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE, companyCode)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE, programCode)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_FIRST_TIME_USER, TRUE)
                    if(!ROUTER_CONFIG.config.isCookieEnabled) {
                        setItemToBrowserStorage(BROWSER_STORAGE_KEY_TOKEN, token)
                        setItemToBrowserStorage(BROWSER_STORAGE_KEY_REFRESH_TOKEN, refreshToken)
                    }
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO, res.data.details.cliams.userDetails.membershipNumber)
                    window.location.href = `#${NAVIGATE_MEMBER_DASHBOARD}` //Fix Me: will be changing based on programType.
                }
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "enrollLoginDetails"))
            })
    };
}

/**
 * Creates Action of type <i>_URL_FETCH_PROFILE_DETAILS</i> with given data
 * @author Geo George
 * @function checkMemberShipNumber
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_FETCH_PROFILE_DETAILS</i>
 *
 */
 export const checkGhostCardNumber = (payload, id) => {
    return async dispatch => {
      dispatch(startButtonSpinner(id, "checkGhostCardNumber"))
      await doPost(_URL_CHECK_MEMBERSHIP_NUMBER, payload)
      .then((response) => {
          dispatch(stopButtonSpinner(id, "checkGhostCardNumber"))
          dispatch({
              type: PREAFFLIATED_DATA,
              payload: response.data
          })
      })
      .catch((error) => {
          dispatch(stopButtonSpinner(id, "checkGhostCardNumber"))
          dispatch({
              type: PREAFFLIATED_ERROR,
              payload: {error: getApiErrorMessage(error.response.data.error)}
          })
      })
    };
  }
