
import { doPostLogin } from '../../common/utils/api'
import { _URL_LOGIN_DETAILS, _URL_SOCIAL_LOGGED_USER } from '../../common/config/config'
import {
  getItemFromBrowserStorage,
  setItemToBrowserStorage,
  BROWSER_STORAGE_KEY_COMPANY_CODE,
  BROWSER_STORAGE_KEY_PROGRAM_CODE,
  BROWSER_STORAGE_KEY_TOKEN,
  BROWSER_STORAGE_KEY_PROGRAM_TYPE,
  BROWSER_STORAGE_KEY_REFRESH_TOKEN,
} from '../../common/utils/storage.utils';
import { fetchCurrentLoginUserData } from '../../common/middleware/redux/commonAction';
import { stopButtonSpinner, startButtonSpinner } from '../../common/components/fieldbank/loader/action';
import { ROUTER_CONFIG } from '../../common/config/routing';

// Action Type
export const SET_LOGIN_DATA = "SET_LOGIN_DATA";
export const SET_SOCIAL_LOGIN_DATA = "SET_SOCIAL_LOGIN_DATA";
export const FORGOT_PSW = "FORGOT_PSW";
export const ENTROLMENT = "ENTROLMENT";

/**
 * Creates Action of type <i>_URL_LOGIN_DETAILS</i> with given data
 *
 * @function loginDetails
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_LOGIN_DETAILS</i>
 *
 */
export const loginDetails = (payload, noOfAttempts, remeberMe, defaultConfig, loginType, id) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "loginDetails"))
    dispatch({
      type: SET_LOGIN_DATA,
      payload: {}
    });
    let { data } = payload;
    data[BROWSER_STORAGE_KEY_COMPANY_CODE] = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
    // data[BROWSER_STORAGE_KEY_PROGRAM_CODE] = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)

    let expireDate = payload.expireDate;
    await doPostLogin(_URL_LOGIN_DETAILS, payload)
      .then(async (response) => {
        dispatch(stopButtonSpinner(id, "loginDetails"))
        if (response.data.details) {
          const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
          let responseHeader = response.headers
          var date = new Date();
          date.setDate(date.getDate() + expireDate);

          if (responseHeader) {
            response.data["show"] = true
            if(!ROUTER_CONFIG.config.isCookieEnabled) {
              let token = responseHeader["ps-token"]
              let refreshtoken = responseHeader["ps-refreshtoken"]
              
              setItemToBrowserStorage(BROWSER_STORAGE_KEY_TOKEN, token)
              setItemToBrowserStorage(BROWSER_STORAGE_KEY_REFRESH_TOKEN, refreshtoken)
            }
            // document.cookie = "token=" + token + "; " + "expires=" + date;
            // document.cookie = "refreshtoken=" + refreshtoken + "; " + "expires=" + date;
            
            delete data[BROWSER_STORAGE_KEY_COMPANY_CODE]
            delete data[BROWSER_STORAGE_KEY_PROGRAM_CODE]
            data["loginType"] = loginType

            if (remeberMe) {
              document.cookie = `rememberMe-${programType}=` + window.btoa(JSON.stringify(data)) + "; " + "expires=" + date;
            } else {
              document.cookie = `rememberMe-${programType}=null`;
            }
            dispatch({
              type: SET_LOGIN_DATA,
              payload: response.data
            });
            dispatch(fetchCurrentLoginUserData(defaultConfig))
            return response.data;
          }
        }
      })
      .catch((error) => {
        console.log("login failed");
        console.log(error);
        dispatch(stopButtonSpinner(id, "loginDetails"))
        if (error.response) {
          let apiResponseError = error.response.data;
          apiResponseError.noOfAttempts = noOfAttempts;
          dispatch({
            type: SET_LOGIN_DATA,
            payload: apiResponseError
          });
        }

      })
  };
}

export const clearLoginResponse = () =>{
  return dispatch => {
    dispatch({
      type: SET_LOGIN_DATA,
      payload: {}
    });
  }
}